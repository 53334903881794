<template>
	<div class="pt-2">
		<v-layout wrap>
			<v-flex xs12 md6>
				<operations-field label="MMSI" :value="mmsi"></operations-field>
			</v-flex>
			<v-flex xs12 md6>
				<operations-field label="IMO" :value="imo"></operations-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex xs12 md6>
				<operations-field label="ETA" :value="convertDatesFromStopAIS(eta)"></operations-field>
			</v-flex>
			<v-flex xs12 md6>
				<operations-field :label="$t('operations.vessels.vesselbeam')" :value="vesselbeam"></operations-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex xs12 md6>
				<operations-field :label="$t('operations.vessels.vessellength')" :value="vessellength"></operations-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import OperationsField from '@/components/operations/map/commonpanel/stop/OperationsField.vue';

export default {
	components: { OperationsField },
	props: {
		stop: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat
		};
	},
	computed: {
		mmsi() {
			return this.stop?.mmsi || '';
		},
		imo() {
			return this.stop?.imo || '';
		},
		eta() {
			return this.stop?.eta || '';
		},
		vesselbeam() {
			return this.stop?.vesselbeam || '';
		},
		vessellength() {
			return this.stop?.vessellength || '';
		}
	},
	methods: {
		convertDatesFromStopAIS(date) {
			return this.$dateTimeUtils.getLocalFormattedDate(date, `${this.dateFormat} ${this.timeFormat}`);
		}
	}
};
</script>
