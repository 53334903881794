<template>
	<div class="pa-2">
		<v-spacer></v-spacer>
		<div></div>
		<initiated v-if="stop.statusid == 'IN'" :stop="stop" />
		<accepted v-if="stop.statusid == 'AC'" :stop="stop" />
		<authorized v-if="stop.statusid == 'AU'" :stop="stop" />
		<planned v-if="stop.statusid == 'PL'" :stop="stop" />
		<ais v-if="stop.isAis" :stop="stop" />
	</div>
</template>

<script>
import CardPanelItemContentInitiated from './cardContentByStatus/CardPanelItemContentInitiated';
import CardPanelItemContentAccepted from './cardContentByStatus/CardPanelItemContentAccepted';
import CardPanelItemContentAuthorized from './cardContentByStatus/CardPanelItemContentAuthorized';
import CardPanelItemContentPlanned from './cardContentByStatus/CardPanelItemContentPlanned';
import CardPanelItemContentAIS from './cardContentByStatus/CardPanelItemContentAIS';

export default {
	components: {
		initiated: CardPanelItemContentInitiated,
		accepted: CardPanelItemContentAccepted,
		authorized: CardPanelItemContentAuthorized,
		planned: CardPanelItemContentPlanned,
		ais: CardPanelItemContentAIS
	},
	props: {
		stop: {
			type: Object,
			required: true
		}
	},
	computed: {
		portcallnumber() {
			return this.stop?.portcallnumber || '';
		},
		shipownername() {
			return this.stop?.shipownername || '';
		},
		eta() {
			return this.stop?.eta || '';
		},
		ata() {
			return this.stop?.ata || '';
		},
		etd() {
			return this.stop?.etd || '';
		},
		atd() {
			return this.stop?.atd || '';
		},
		atc() {
			return this.stop?.atc || '';
		},
		location() {
			return this.stop?.berthname || '';
		}
	},
	created() {}
};
</script>
