<template>
	<div class="pt-2">
		<v-layout wrap>
			<v-flex xs12 md6>
				<operations-field label="MMSI" :value="mmsi"></operations-field>
			</v-flex>
			<v-flex xs12 md6>
				<operations-field label="IMO" :value="imo"></operations-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex xs12 md6>
				<operations-field label="ATA" :value="convertDatesFromStopAIS(ata)"></operations-field>
			</v-flex>
			<v-flex xs12 md6>
				<operations-field label="ETD" :value="convertDatesFromStopAIS(etd)"></operations-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex xs12 md6>
				<operations-field :label="$t('operations.vessels.location')" :value="location"></operations-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import OperationsField from '@/components/operations/map/commonpanel/stop/OperationsField.vue';

export default {
	components: { OperationsField },
	props: {
		stop: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat
		};
	},
	computed: {
		mmsi() {
			return this.stop?.mmsi || '';
		},
		imo() {
			return this.stop?.vesselimo || '';
		},
		ata() {
			return this.stop?.ata || '';
		},
		etd() {
			return this.stop?.etd || '';
		},
		location() {
			return this.stop?.berthname || '';
		}
	},
	methods: {
		convertDatesFromStopAIS(date) {
			return this.$dateTimeUtils.getLocalFormattedDate(date, `${this.dateFormat} ${this.timeFormat}`);
		}
	}
};
</script>
